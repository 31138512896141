import React from 'react';
import styled from 'styled-components';
import { ModuleTemplate, ModuleType, useClassNames } from '@vodafoneis/sjonvarpskjarni-js-lib';
import { ImageComponent, ImageComponentContainer } from './ImageComponent';
import { Highlight } from './Highlight';
import { useMediaComponentInfo } from '../hooks/useMediaComponentInfo';
import { MediaComponentProps } from '../models/MediaComponentProps';

const { POSTER, HOPSTER } = ModuleType;
const { OVERLAY_ICON } = ModuleTemplate;

type MediaComponentContentProps = {
	size?: ModuleType;
	template?: ModuleTemplate;
	strapiImage?: string;
	backgroundImages?: string[];
	iconImage?: string;
	title?: string;
	subtitle?: string;
	progress?: number;
	isStrapi?: boolean;
};

const MediaComponentContentContainer = styled.div`
	border-radius: 6px;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const MediaComponentImageComponentContainer = styled(ImageComponentContainer)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const MediaComponentHopsterImageComponentContainer = styled(MediaComponentImageComponentContainer)`
	background: none;
`;

const MediaComponentImageComponent = styled(ImageComponent)`
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 100%;
`;

const MediaComponentContent: React.FC<MediaComponentContentProps> = ({ size, backgroundImages, iconImage, title, subtitle, progress, isStrapi }) => {
	if (size === HOPSTER) {
		return (
			<MediaComponentHopsterImageComponentContainer>
				<MediaComponentImageComponent className={'background-image'} images={backgroundImages} isStrapiImage={isStrapi} />
			</MediaComponentHopsterImageComponentContainer>
		);
	}

	return (
		<>
			<MediaComponentContentContainer>
				<MediaComponentImageComponentContainer>
					<MediaComponentImageComponent className={'background-image'} images={backgroundImages} isStrapiImage={isStrapi} />
				</MediaComponentImageComponentContainer>
				<ImageComponent className={'icon-image'} images={[iconImage]} />
				{title && (
					<div className={'title-container'}>
						<div className={'title'}>{title}</div>
					</div>
				)}
				{subtitle && <div className={'supporting-text'}>{subtitle}</div>}
				<div className={'progress-container'}>
					<div className={'progress'} style={{ width: `${Math.round(progress * 100)}%` }} />
				</div>
			</MediaComponentContentContainer>
			<Highlight />
		</>
	);
};

export const MediaComponent: React.FC<MediaComponentProps> = (props) => {
	const { type, size = POSTER, template = OVERLAY_ICON, className, isStrapi } = props;
	const { title, subtitle, progress, backgroundImages, iconImage, handleClick } = useMediaComponentInfo(props);

	const classNames = useClassNames(className, {
		'media-component': true,
		[type.toLowerCase()]: !!type,
		[size.toLowerCase()]: !!size,
		[`template-${template.toLowerCase()}`]: !!template,
		'with-supporting-text': !!subtitle,
		'without-supporting-text': !subtitle,
	});

	return (
		<div onClick={handleClick} className={classNames}>
			<MediaComponentContent
				size={size}
				template={template}
				backgroundImages={backgroundImages}
				iconImage={iconImage}
				title={title}
				subtitle={subtitle}
				progress={progress}
				isStrapi={isStrapi}
			/>
		</div>
	);
};
